import { memo } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';

import * as StyledPage from 'styles';
import { Flex } from 'styles';

import LabelAndText from 'components/LabelAndText';
import ImageUploader from 'components/ImageUploader';
import { handleError } from 'commons/helper';

import vendorApi from 'service/vendor/info';

const PaymentInfo = ({
  payment: {
    paymentMethodValue,
    paymentDateValue,
    bankValue,
    accountNumber,
    depositor,
    bankBookVendorImageName,
    bankBookVendorImageId,
  } = {},
}) => {
  const handleImageDownloadClick = async () => {
    try {
      const blob = await vendorApi.getImageById(bankBookVendorImageId);
      saveAs(blob, bankBookVendorImageName);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <StyledPage.Card title='결제 정보'>
      <Flex>
        <LabelAndText label='결제 유형' text={paymentMethodValue} />
        <LabelAndText label='결제일' text={paymentDateValue} />
      </Flex>
      <Flex>
        <LabelAndText label='은행' text={bankValue} />
        <LabelAndText label='계좌번호' text={accountNumber} />
      </Flex>
      <Flex>
        <LabelAndText label='예금주' text={depositor} />
        <ImageUploader
          id='bankBookVendorImageName'
          title='통장사본'
          imageName={bankBookVendorImageName || '파일이 없습니다.'}
          imageId={bankBookVendorImageId}
          onImageDownloadClick={handleImageDownloadClick}
          disabled
        />
      </Flex>
    </StyledPage.Card>
  );
};

PaymentInfo.propTypes = {
  payment: PropTypes.shape({
    paymentMethodValue: PropTypes.string,
    paymentDateValue: PropTypes.string,
    bankValue: PropTypes.string,
    accountNumber: PropTypes.string,
    depositor: PropTypes.string,
    bankBookVendorImageName: PropTypes.string,
    bankBookVendorImageId: PropTypes.number,
  }),
};

export default memo(PaymentInfo);
